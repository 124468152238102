<script>
import {reactive, toRefs} from 'vue';
import TeamCalendar from "./TeamCalendar.vue";

export default {
  name: 'CalendarList',
  components: {TeamCalendar},
  props: {
    teams: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const data = reactive({
      timeStep: 30
    });

    return {
      ...toRefs(data),
    }
  }
}
</script>

<template>
  <div class="row">
    <div class="col-12 d-flex gap-3">
      <el-button-group class="btn-group-cal">
        <el-button type="primary" @click="timeStep = 15" :disabled="timeStep === 15">Zobrazení po 15 min.</el-button>
        <el-button type="primary" @click="timeStep = 30" :disabled="timeStep === 30">Zobrazení po 30 min.</el-button>
        <el-button type="primary" @click="timeStep = 60" :disabled="timeStep === 60">Zobrazení po 60 min.</el-button>
      </el-button-group>
    </div>
  </div>

  <div class="row calendar-list ">
    <el-tabs tab-position="left">
      <el-tab-pane v-for="team in teams.filter(f => !f.isDisabled)"
                   :key="team.id"
                   :label="team.nameShort"
                   lazy>
        <a :href="team.link" class="d-none d-sm-block position-absolute right-0">
          <el-tag type="primary" size="large" effect="plain">Otevřít v okně</el-tag>
        </a>

        <team-calendar :team-id="team.id"
                       :time-step="timeStep"
                       :pteam="team"
                       direction="vertical"
                       hide-settings></team-calendar>
      </el-tab-pane>
    </el-tabs>
  </div>


<!--  <div class="row mx-3 calendar-list">-->
<!--    <div v-for="team in teams" :key= "team.id" class="col-sm-4 col-lg-3 col-xl-2 col-xxl-1">-->
<!--      <team-calendar :team-id="team.id"-->
<!--                     :time-step="timeStep"-->
<!--                     :label="team.label"-->
<!--                     :pteam="team"-->
<!--                     direction="vertical"-->
<!--                     hide-settings-->
<!--                     hide-day-name></team-calendar>-->
<!--    </div>-->
<!--  </div>-->
</template>

<style scoped>

</style>