<script>
import {onMounted, reactive, toRefs, watch} from 'vue';
import router from "../../router";

export default {
  name: 'DataPaginator',
  props: {
    riderId: {
      type: Number,
      default: null
    }
  },
  setup(props) {
    const data = reactive({
      currentPage: 1,
      pageSize: 100,
      total: 0,
    });

    function load() {
      helpers.server.fetchPost(router.front.xhr.view,
          {
            action: 'gpsData',
            currentPage: data.currentPage,
            pageSize: data.pageSize,
            riderId: props.riderId
          })
          .then((res) => {
            window.mitt.emit('clearMap');

            data.total = res.total;
            data.pageSize = res.pageSize;
            data.currentPage = res.currentPage;
            res.items.forEach((item) => {
              let type = 'Udp' + item.type.charAt(0).toUpperCase() + item.type.slice(1);
              window.mitt.emit(type, item.data);
            });
          });
    }


    //watch currentPage + pageSize
    watch([_ => data.currentPage, _ => data.pageSize], _ => {
      load();
    });

    onMounted(() => {
      load();
    });

    return {
      ...toRefs(data),
      load
    }
  }
}
</script>

<template>
  <el-pagination
      v-model:current-page="currentPage"
      v-model:page-size="pageSize"
      :page-sizes="[25, 50, 75, 100, 200, 300, 400]"
      :background="true"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
  />
</template>

<style scoped>

</style>