<script>
import {reactive, toRefs, computed, watch, onMounted} from 'vue';
import {durationInMinutes, dateName, dateToTime, dateToMinutes} from "../../dateHelper";

export default {
  name: 'EventEditor',
  props: {
    minDate: {
      type: Date,
      required: true
    },
    maxDate: {
      type: Date,
      required: true
    },
    calEvent: {
      type: Object,
      required: true
    }
  },
  emits: ['changed'],
  setup(props) {
    const data = reactive({
      ev: null,
      evStart: null,
      evEnd: null,
      evDateName: null,
      evDuration: null,
      disableDecreaseTime: computed(() => {
        let newDate = new Date(data.ev.start);
        if (dateToMinutes(newDate) === 0) {
          return true;
        }
        newDate.setMinutes(newDate.getMinutes() - 15);
        return newDate < props.minDate
      }),
      disableDecreaseDuration: computed(() => {
        return data.evDuration === 15;
      }),
      disableIncreaseTime: computed(() => {
        let newDate = new Date(data.ev.end);
        if (dateToMinutes(newDate) === 0) {
          return true;
        }

        newDate.setMinutes(newDate.getMinutes() + 15);
        return newDate > props.maxDate
      }),
      disableIncreaseDuration: computed(() => {
        let newDate = new Date(data.ev.end);
        newDate.setMinutes(newDate.getMinutes() + 15);
        return newDate > props.maxDate
      }),
    });

    watch(() => props.calEvent, refreshTemplate, {immediate: true, deep: true});

    function decreaseTime() {
      let newStart = new Date(data.ev.start);
      newStart.setMinutes(newStart.getMinutes() - 15);
      props.calEvent.start = newStart;

      let newEnd = new Date(data.ev.end);
      newEnd.setMinutes(newEnd.getMinutes() - 15);
      props.calEvent.end = newEnd;
    }

    function increaseTime() {
      let newStart = new Date(data.ev.start);
      newStart.setMinutes(newStart.getMinutes() + 15);
      props.calEvent.start = newStart;

      let newEnd = new Date(data.ev.end);
      newEnd.setMinutes(newEnd.getMinutes() + 15);
      props.calEvent.end = newEnd;
    }

    function decreaseDuration() {
      let newEnd = new Date(data.ev.end);
      newEnd.setMinutes(newEnd.getMinutes() - 15);
      props.calEvent.end = newEnd;
    }

    function increaseDuration() {
      let newEnd = new Date(data.ev.end);
      newEnd.setMinutes(newEnd.getMinutes() + 15);
      props.calEvent.end = newEnd;
    }

    function refreshTemplate(value) {
      data.evStart = dateToTime(value.start);
      data.evEnd = dateToTime(value.end);
      data.evDateName = dateName(value.start);
      data.evDuration = durationInMinutes(value.start, value.end);
      data.ev = value;
    }

    function deleteEvent() {
      window.mitt.emit('deleteEvent', data.ev);
    }

    onMounted(() => {
      window.mitt.on('durationChangeEvent', (ev) => {
        if (ev.eid !== data.ev.eid) {
          return;
        }
        refreshTemplate(ev);
      });
    });

    return {
      ...toRefs(data),
      durationInMinutes, dateName, dateToTime, decreaseTime, increaseTime,
      decreaseDuration, increaseDuration, deleteEvent
    }
  }
}
</script>

<template>
  <div class="event-editor" v-if="ev !== null">
    <el-button :disabled="disableDecreaseTime" @click="decreaseTime">- 15 min</el-button>
    {{ evDateName }} {{ evStart }} - {{ evEnd }}
    <el-button :disabled="disableIncreaseTime" @click="increaseTime">+ 15 min</el-button>

    <el-divider direction="vertical"/>

    <el-button :disabled="disableDecreaseDuration" @click="decreaseDuration">- 15 min</el-button>
    Trvání: {{ evDuration }} minut
    <el-button :disabled="disableIncreaseDuration || disableIncreaseTime" @click="increaseDuration">+ 15 min</el-button>

    <el-popconfirm title="Opravdu odstranit?" @confirm="deleteEvent" width="200" confirm-button-text="Ano" cancel-button-text="Ne">
      <template #reference>
        <el-button type="danger" class="delete-button">Odstranit</el-button>
      </template>
    </el-popconfirm>

  </div>


</template>

<style scoped>
.event-editor {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  color: #333;
  gap: 8px;
}

.delete-button {
  position: absolute;
  right: 10px;
}
</style>