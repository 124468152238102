<script>
import {onMounted, reactive, toRefs, watch, watchEffect} from 'vue';
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'
import {dateToMinutes, durationInMinutes} from "../../dateHelper";

export default {
  name: 'RegistrationCalendar',
  components: {VueCal},
  props: {
    team: {
      type: Object,
      required: true
    },
    events: {
      type: Array,
      required: true
    },
    selectedDate: {
      type: String,
      required: true
    },
    hideWeekdays: {
      type: Array,
      default: () => []
    },
    timeFrom: {
      type: Number,
      default: 0
    },
    timeTo: {
      type: Number,
      default: 24 * 60
    },
    timeStep: {
      type: Number,
      required: true
    },
    editableEvents: {
      type: Object,
      default: () => ({title: false, drag: true, resize: true, delete: true, create: true})
    },
  },
  emits: ['update:modelValue'],

  setup(props, {emit}) {
    const data = reactive({
      count: 0,
      vuecal: null,
      newEvents: [],
    });

    function fireEventsChanged() {
      emit('update:modelValue', data.newEvents)
    }

    function validateEvent(event) {
      if (durationInMinutes(event.start, event.end) < 15) {
        deleteEv(event);
      }
    }

    function createEvent(event) {
      let newEvent = {
        eid: event._eid,
        start: event.start,
        end: event.end,
      };
      data.newEvents.push(newEvent);
      fireEventsChanged();
      validateEvent(newEvent);
    }

    function durationChangeEvent(e, fire = true, mitt = true) {
      const index = data.newEvents.findIndex(event => event.eid === e.event._eid);
      if (index !== -1) {
        data.newEvents[index].start = e.event.start;
        data.newEvents[index].end = e.event.end;
        if (fire) {
          fireEventsChanged();

          if (mitt) {
            window.mitt.emit('durationChangeEvent', data.newEvents[index]);
          }

          validateEvent(data.newEvents[index]);
        }
      }
    }

    function dropEvent(e, fire = true) {
      durationChangeEvent(e, fire, false);
    }

    function deleteEvent(e, fire = true) {
      const index = data.newEvents.findIndex(event => event.eid === e._eid);
      if (index !== -1) {
        data.newEvents.splice(index, 1);
        if (fire) {
          fireEventsChanged();
        }
      }
    }

    watchEffect(() => {
      data.newEvents.forEach((event) => {
        watch(() => event, (newVal) => {
          changeCalendarEvent(newVal);
        }, { deep: true });
      });
    });

    function changeCalendarEvent(toEvent) {
      let event = data.vuecal.view.events.find(e => e._eid === toEvent.eid);
      if (event) {
        event.start = toEvent.start;
        event.end = toEvent.end;
        event.startTimeMinutes = dateToMinutes(event.start);
        event.endTimeMinutes = dateToMinutes(event.end);
      }
    }

    function deleteEv(ev) {
      let index = data.vuecal.view.events.findIndex(e => e._eid === ev.eid);
      if (index !== -1) {
        data.vuecal.view.events.splice(index, 1);
        deleteEvent({ _eid: ev.eid });
      }
    }

    onMounted(() => {
      window.mitt.on('deleteEvent', (ev) => {
        deleteEv(ev);
      });
    });

    return {
      ...toRefs(data),
      createEvent, durationChangeEvent, deleteEvent, dropEvent
    }
  }
}
</script>

<template>
  <div>
    <vue-cal
        ref="vuecal"
        :events="events"
        :selected-date="selectedDate"
        hide-view-selector
        hide-title-bar
        :hide-weekdays="hideWeekdays"
        hide-weekends
        :snap-to-time="15"
        :time-from="timeFrom"
        :time-to="timeTo"
        :time-step="timeStep"
        locale="cs"
        :disable-views="['  years', 'year', 'month', 'day']"
        :editable-events="editableEvents"
        :drag-to-create-threshold="20"

        @event-drag-create="createEvent"
        @event-delete="deleteEvent"
        @event-duration-change="durationChangeEvent"
        @event-drop="dropEvent"
    />
  </div>
</template>

<style>
.vuecal__menu, .vuecal__cell-events-count {
  background-color: #cb0dff;
}

.vuecal__title-bar {
  background-color: #ff9400;
}

.vuecal__cell--today, .vuecal__cell--current {
  background-color: rgba(0, 0, 255, 0.4);
}

.vuecal:not(.vuecal--day-view) .vuecal__cell--selected {
  background-color: rgba(244, 245, 247, 0);
}

.vuecal__cell--selected:before {
  border-color: rgb(223, 223, 224);
}

/* Cells and buttons get highlighted when an event is dragged over it. */
.vuecal__cell--highlighted:not(.vuecal__cell--has-splits),
.vuecal__cell-split--highlighted {
  background-color: rgba(220, 6, 38, 0.5);
}

.vuecal__arrow.vuecal__arrow--highlighted,
.vuecal__view-btn.vuecal__view-btn--highlighted {
  background-color: rgba(255, 67, 0, 0.25);
}

.vuecal__event {
  background-color: steelblue;
  color: white;
  border-bottom: 1px solid #e5e6e7;
  position: relative;
}

.vuecal__event.block-event {
  background: repeating-linear-gradient(45deg, transparent, transparent 10px, #f2f2f2 10px, #f2f2f2 20px); /* IE 10+ */
  color: #999;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: xxx-large;
}

.vuecal__event.block-event .vuecal__event-time {
  display: none;
  align-items: center;
}

.btn-group-cal {
  margin-bottom: 15px;
}

.vuecal__event-content {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 5px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.vuecal__header {
  display: none;
}

.vuecal__cell-events .event-unconfirmed {
  background-color: #ff9400;
}

.vuecal__cell-events .event-confirmed {
  background-color: #44c12f;
}
</style>