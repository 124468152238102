<script>
import {computed, onMounted, reactive, toRefs, watch} from 'vue';
import RegistrationCalendar from "../Components/Registration/RegistrationCalendar.vue";
import EventEditor from "../Components/Registration/EventEditor.vue";
import router from "../router";

export default {
  name: 'RegistrationForm',
  components: {EventEditor, RegistrationCalendar},
  props: {
    teamId: {
      type: Number,
      default: 0
    }
  },
  setup(props) {
    const data = reactive({
      flash: null,
      isHelperVisible: false,
      elForm: null,
      calendarLeft: null,
      calendarRight: null,
      minDate: new Date('2024-04-30 19:00'),
      maxDate: new Date('2024-05-01 19:00'),
      teams: [],
      events: [],
      timeStep: 15,
      direction: 'vertical',
      form: {
        fullname: null,
        nickname: null,
        email: null,
        teamId: null,
        leftEvents: [],
        rightEvents: [],
      },
      leftEventsSorted: computed(_ => {
        return data.form.leftEvents.sort((a, b) => {
          return a.start - b.start;
        })
      }),
      rightEventsSorted: computed(_ => {
        return data.form.rightEvents.sort((a, b) => {
          return a.start - b.start;
        })
      })
    });

    watch(_ => data.form.teamId, (teamId) => {
      loadTeamEvents(teamId);
    });

    function loadTeamEvents(teamId) {
      //vse se musi vynulovat
      data.form.leftEvents = [];
      data.form.rightEvents = [];


      window.helpers.server
          .fetchPost(router.front.xhr.registration, {action: 'events', teamId})
          .then(events => {
            // noinspection JSValidateTypes
            data.events = events;
          });
    }

    function submit() {
      data.flash = null;
      data.elForm.validate((valid) => {
        if (valid) {
          let events = data.form.leftEvents.concat(data.form.rightEvents);
          window.helpers.server
              .fetchPost(router.front.xhr.registration, {action: 'register', ...data.form, events: JSON.stringify(events)})
              .then(response => {
                data.flash = response.flash;
                data.elForm.resetFields();
              });
        }
      });
    }

    function loadTeams() {
      window.helpers.server
          .fetchPost(router.front.xhr.registration, {action: 'teams'})
          .then(teams => {
            // noinspection JSValidateTypes
            data.teams = teams;

            if (props.teamId > 0) {
              data.form.teamId = props.teamId
            }
          });
    }

    function eventsValidator(rule, e, callback) {

      [data.form.leftEvents, data.form.rightEvents].forEach(events => {
        //kontrola zda se casy vzajemne neprekryvaji
        for (let i = 0; i < events.length; i++) {
          for (let j = i + 1; j < events.length; j++) {
            if (events[i].start < events[j].end && events[i].end > events[j].start) {
              callback(new Error('Časy se nesmí překrývat'));
              return;
            }
          }
        }

        //kontrola zda se neprekryvaji i s jinymi eventy v data.events
        for (let i = 0; i < events.length; i++) {
          for (let j = 0; j < data.events.length; j++) {
            let start = new Date(data.events[j].start);
            let end = new Date(data.events[j].end);
            if (events[i].start < end && events[i].end > start) {
              callback(new Error('Vybraný čas se překrývá s již zarezervovaným časem'));
              return;
            }
          }
        }

        //zadny event nesmi zasahovat mimo minDate a maxDate
        for (let i = 0; i < events.length; i++) {
          if (events[i].start < data.minDate || events[i].end > data.maxDate) {
            callback(new Error('Časy musí být v rozmezí 30.4. 19:00 - 1.5. 19:00'));
            return;
          }
        }
      });

      if (data.form.leftEvents.length === 0 && data.form.rightEvents.length === 0) {
        callback(new Error('Vyberte prosím alespoň jeden čas'));
        return;
      }

      callback();
    }

    onMounted(_ => {
      loadTeams();
    });

    return {
      ...toRefs(data),
      submit, eventsValidator
    }
  }
}
</script>

<template>
  <el-form
      class="registration-form"
      label-width="110px"
      ref="elForm"
      :model="form"
      @submit.native.prevent="submit">
    <el-form-item label="Celé jméno" prop="fullname" class="flex-column flex-md-row" :rules="[ { required: true, message: 'Vyplňte prosím celé jméno' } ]">
      <el-input v-model="form.fullname" :maxlength="30" show-word-limit placeholder="Vaše celé jméno - nebude zobrazeno v kalendáři"></el-input>
    </el-form-item>

    <el-form-item label="E-mail" prop="email" class="flex-column flex-md-row" :rules="[
        { required: true, message: 'Vyplňte prosím e-mail' },
        {
          type: 'email',
          message: 'Zadejte prosím správnou e-mailovou adresu',
          trigger: ['blur', 'change'],
        }]">
      <el-input v-model="form.email" type="text" show-word-limit :maxlength="50" placeholder="Váš e-mail pro zaslání potvrzení vybraných časů"></el-input>
    </el-form-item>

    <el-form-item label="Přezdívka" prop="nickname" class="flex-column flex-md-row" :rules="[ { required: true, message: 'Vyplňte prosím přezdívku' } ]">
      <el-input v-model="form.nickname" :maxlength="30" show-word-limit placeholder="Vaše přezdívka - bude zobrazena v kalendáři"></el-input>
    </el-form-item>

    <el-form-item label="Tým" prop="teamId" class="flex-column flex-md-row" :rules="[ { required: true, message: 'Vyberte prosím Váš tým' } ]">
      <el-select v-model="form.teamId" placeholder="Vyberte tým, který se vám líbí nejvíce" filterable>
        <el-option
            v-for="team in teams"
            :key="team.id"
            :disabled="team.isDisabled"
            :label="team.name"
            :value="team.id">
        </el-option>
      </el-select>
    </el-form-item>

    <el-form-item class="flex-column flex-md-row">
      <el-alert title="Názvy týmů jsou vymyšlené, zvolte takový, jehož název se vám bude nejvíce líbít a bude mít volné časy, které vám vyhovují." type="info" :closable="false" />
    </el-form-item>

    <div v-if="form.teamId">
      <el-form-item class="flex-column flex-md-row">
        <div class="d-flex flex-column w-100">
          <p>Časy které si rezervujete pro sebe:</p>

          <template v-if="form.leftEvents.length === 0 && form.rightEvents.length === 0">
            <p class="border-left-danger">
              Žádné časy nejsou vybrány.
              <br>Klepnutím a tažením dole v kalendáři nastavíte svůj čas.
              <br>Časy nastavte prosím všechny na celých 24h.
              <br>Nesprávně vytvořený čas lze přesunout, upravit nebo úplně odstranit (stisknutím a držením na čase se zobrazí po chvilce tlačítko odstranit), viz nápověda:
            </p>
            <el-button @click="isHelperVisible = !isHelperVisible" class="mb-3" type="primary">Zobrazit/skrýt nápovědu</el-button>
            <img v-if="isHelperVisible" src="../Images/helper.gif" alt="Helper" class="img-thumbnail mb-3">
          </template>
          <template v-else>
            <event-editor v-for="event in leftEventsSorted" :key="event.eid" :cal-event="event" :min-date="minDate" :max-date="maxDate"></event-editor>
            <event-editor v-for="event in rightEventsSorted" :key="event.eid" :cal-event="event" :min-date="minDate" :max-date="maxDate"></event-editor>
          </template>
        </div>
      </el-form-item>
    </div>

    <el-divider v-if="form.teamId"></el-divider>

    <div class="row px-3" v-if="form.teamId">
      <div>
        <h3>Kalendář týmu {{ teams.find(f => f.id === form.teamId).name }}</h3>
      </div>
      <div class="col-12 d-flex gap-3">
        <el-button-group class="btn-group-cal">
          <el-button type="primary" @click="timeStep = 15" :disabled="timeStep === 15">Zobrazení po 15 min.</el-button>
          <el-button type="primary" @click="timeStep = 30" :disabled="timeStep === 30">Zobrazení po 30 min.</el-button>
          <el-button type="primary" @click="timeStep = 60" :disabled="timeStep === 60">Zobrazení po 60 min.</el-button>
        </el-button-group>

        <el-button-group class="btn-group-cal d-none d-md-block">
          <el-button type="primary" @click="direction = 'vertical'" :disabled="direction === 'vertical'">Pod sebou</el-button>
          <el-button type="primary" @click="direction = 'horizontal'" :disabled="direction === 'horizontal'">Vedle sebe</el-button>
        </el-button-group>
      </div>
    </div>

    <div class="row mb-3 px-3" v-if="form.teamId">
      <div :class="{ 'col-md-6':direction === 'horizontal', 'col-12':direction === 'vertical' }">
        <h3>Úterý 30. 4.</h3>
        <RegistrationCalendar
            :key="'left' + form.teamId"
            :time-from="19*60"
            :time-to="24*60"
            selected-date="2024-04-30"
            :hide-weekdays="[ 1, 3, 4, 5 ]"
            ref="calendarLeft"
            :team="teams.find(f => f.id === form.teamId)"
            :events="events"
            :time-step="timeStep"
            v-model="form.leftEvents">
        </RegistrationCalendar>
      </div>

      <div :class="{ 'col-md-6':direction === 'horizontal', 'col-12':direction === 'vertical', 'mt-3':direction === 'vertical' }">
        <h3>Středa 1. 5.</h3>
        <RegistrationCalendar
            :key="'right' + form.teamId"
            :time-from="0"
            :time-to="19*60"
            selected-date="2024-05-01"
            :hide-weekdays="[ 1, 2, 4, 5 ]"
            ref="calendarRight"
            :team="teams.find(f => f.id === form.teamId)"
            :events="events"
            :time-step="timeStep"
            v-model="form.rightEvents">
        </RegistrationCalendar>
      </div>
    </div>

    <el-form-item prop="leftEvents" class="flex-column flex-md-row" :rules="[ { required: true, validator: eventsValidator } ]">
      <el-button type="success" size="large" native-type="submit">Rezervovat</el-button>
    </el-form-item>

    <el-form-item class="flex-column flex-md-row">
      <el-alert title="Po stisku tlačítka 'Rezervovat' vám budou vybrané časy rezervovány a odeslán e-mail s odkazem pro potvrzení. Pokud časy do 2 dnů (48 hodin) nepotvrdíte, budou opět uvolněny pro ostatní." type="info" :closable="false" />
      <el-alert v-if="flash" :title="flash.message" :type="flash.type" closable @close="flash = null" effect="dark"></el-alert>
    </el-form-item>

  </el-form>

</template>

<style>
.registration-form .el-form-item__label {
  display: block;
  margin-left: .5rem;
}
</style>

<style scoped>
.registration-form {
  background-color: #fbfcff;
  padding: 24px 8px 8px 8px;
}

.border-left-danger {
  border-left: 4px solid #f56c6c;
  padding-left: 10px;
  font-weight: bold;
}
</style>
