export function dateToString(value) {
	let year = value.getFullYear();
	let month = String(value.getMonth() + 1).padStart(2, '0');
	let date = String(value.getDate()).padStart(2, '0');
	let hours = String(value.getHours()).padStart(2, '0');
	let minutes = String(value.getMinutes()).padStart(2, '0');

	return `${year}-${month}-${date} ${hours}:${minutes}`;
}

export function dateToMinutes(date) {
	let begin = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
	return durationInMinutes(begin, date);
}

export function durationInMinutes(start, end) {
	let dateStart = new Date(start);
	let dateEnd = new Date(end);
	let diffms = dateEnd - dateStart;
	return diffms / 60000;
}

export function dateName(date, full = true) {
	date = new Date(date);

	let days = [ "Neděle", "Pondělí", "Úterý", "Středa", "Čtvrtek", "Pátek", "Sobota" ];
	if (!full) {
		days = [ "Ne", "Po", "Út", "St", "Čt", "Pá", "So" ];
	}

	return days[date.getDay()];
}

export function dateToTime(date) {
	date = new Date(date);
	let hours = date.getHours().toString().padStart(2, '0');
	let minutes = date.getMinutes().toString().padStart(2, '0');
	return `${hours}:${minutes}`;
}