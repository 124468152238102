<script>
import {onMounted, reactive, toRefs} from 'vue';
import RegistrationCalendar from "../Registration/RegistrationCalendar.vue";
import router from "../../router";

export default {
  name: 'TeamCalendar',
  components: {RegistrationCalendar},
  props: {
    teamId: {
      type: Number,
      required: true,
    },
    direction: {
      type: String,
      default: 'horizontal',
    },
    hideSettings: {
      type: Boolean,
      default: false,
    },
    hideDayName: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    pteam: {
      type: Object,
      default: null,
    },
    timeStep: {
      type: Number,
      default: 15,
    },
  },
  setup(props) {
    const data = reactive({
      team: props.pteam,
      events: [],
      leftEvents: [],
      rightEvents: [],
    });

    function loadEvents() {
      window.helpers.server.fetchPost(router.front.xhr.registration, {
        action: 'events',
        teamId: props.teamId
      }).then(events => {
        data.events = events;
      });
    }

    function init() {
      if (data.team !== null) {
        loadEvents();
        return;
      }

      window.helpers.server.fetchPost(router.front.xhr.registration, {
        action: 'team',
        teamId: props.teamId
      }).then(team => {
        data.team = team;
        loadEvents();
      });
    }

    onMounted(_ => {
      init();
    });

    return {
      ...toRefs(data),
    }
  }
}
</script>

<template>
  <div>
    <div class="row" v-if="team !== null && hideSettings === false">
      <div class="col-12 d-flex gap-3">
        <el-button-group class="btn-group-cal">
          <el-button type="primary" @click="timeStep = 15" :disabled="timeStep === 15">Zobrazení po 15 min.</el-button>
          <el-button type="primary" @click="timeStep = 30" :disabled="timeStep === 30">Zobrazení po 30 min.</el-button>
          <el-button type="primary" @click="timeStep = 60" :disabled="timeStep === 60">Zobrazení po 60 min.</el-button>
        </el-button-group>

        <el-button-group class="btn-group-cal d-none d-md-block">
          <el-button type="primary" @click="direction = 'vertical'" :disabled="direction === 'vertical'">Pod sebou</el-button>
          <el-button type="primary" @click="direction = 'horizontal'" :disabled="direction === 'horizontal'">Vedle sebe</el-button>
        </el-button-group>
      </div>

    </div>

    <div class="row" v-if="team !== null">
      <div :class="{ 'col-md-6':direction === 'horizontal', 'col-12':direction === 'vertical' }">
        <h4 v-if="label">{{ label }}</h4>
        <h3 v-if="hideDayName === false">Úterý 30. 4.</h3>
        <RegistrationCalendar
            :time-from="19*60"
            :time-to="24*60"
            selected-date="2024-04-30"
            :hide-weekdays="[ 1, 3, 4, 5 ]"
            :team="team"
            :events="events"
            :time-step="timeStep"
            :editable-events="{ title: false, drag: false, resize: false, delete: false, create: false }"
            v-model="leftEvents">
        </RegistrationCalendar>
      </div>

      <div :class="{ 'col-md-6':direction === 'horizontal', 'col-12':direction === 'vertical', 'mt-3':direction === 'vertical' }">
        <h3 v-if="hideDayName === false">Středa 1. 5.</h3>
        <RegistrationCalendar
            :time-from="0"
            :time-to="19*60"
            selected-date="2024-05-01"
            :hide-weekdays="[ 1, 2, 4, 5 ]"
            :team="team"
            :events="events"
            :time-step="timeStep"
            :editable-events="{ title: false, drag: false, resize: false, delete: false, create: false }"
            v-model="rightEvents">
        </RegistrationCalendar>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>