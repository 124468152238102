import {
	i18n,
	ElementPlus,
	createApp, naja, netteForms, getElementLocale
} from './core.js';


//Matomo
import VueMatomo from 'vue-matomo'
import Web from "./web";
// import VueGtag from "vue-gtag";

//COMPONENTS
import RegistrationForm from "./Forms/RegistrationForm.vue";
import TeamCalendar from "./Components/Calendar/TeamCalendar.vue";
import CalendarList from "./Components/Calendar/CalendarList.vue";
import UdpViewer from "./Components/UdpViewer/UdpViewer.vue";
import DataSwitcher from "./Components/Data/DataSwitcher.vue";
import LeafletMap from "./Components/Map/LeafletMap.vue";
import ContactForm from "./Forms/ContactForm.vue";
import LinkRiders from "./Components/Data/LinkRiders.vue";
import {onMounted} from "vue";

export const app = createApp({
	setup() {
		onMounted(() => {
			window.Nette = netteForms;
			naja.initialize.bind(naja);
			netteForms.initOnLoad();
			Web.initOnLoad();
		});
		return {}
	}
})
	.component(RegistrationForm.name, RegistrationForm)
	.component(TeamCalendar.name, TeamCalendar)
	.component(CalendarList.name, CalendarList)
	.component(UdpViewer.name, UdpViewer)
	.component(DataSwitcher.name, DataSwitcher)
	.component(LeafletMap.name, LeafletMap)
	.component(ContactForm.name, ContactForm)
	.component(LinkRiders.name, LinkRiders)
	.use(ElementPlus, {locale: getElementLocale()})
	.use(i18n)
	.use(VueMatomo, {
		host: 'https://matomo.iprotebe.cz',
		siteId: 1,
	})
	// .use(VueGtag, {
	// 	config: {id: "G-LMB2MCSTJD"}
	// })
	.mount('#app');

window._paq.push(['trackPageView']);
