<script>
import {onMounted, reactive, toRefs} from 'vue';
import router from "../../router";
import Sockette from "sockette";
import DataPaginator from "../Data/DataPaginator.vue";

export default {
  name: 'UdpViewer',
  components: { DataPaginator},
  props: {
    wsurl: {
      type: String,
      required: true
    },
    wsMaxAttempts: {
      type: Number,
      default: Infinity
    },
    wsTimeout: {
      type: Number,
      default: 5000
    },
    riderId: {
      type: Number,
      default: null
    }
  },
  setup(props) {
    const data = reactive({
      liveData: true,
      mouseOver: false,
      lastUpdate: null,
      items: [],
      socket: null,
      state: 'info',
      stateText: 'Disconnected',
    });

    function load() {
      helpers.server.fetchPost(router.front.xhr.view, {action: 'udpLastData', lastUpdate: data.lastUpdate})
          .then((res) => {
            data.lastUpdate = res.lastUpdate;
            //vsechny prijate gps_items z db upravime a po jednom vlozime

            res.items.forEach((item) => {
              addItem(item);
            });

            // data.items.unshift(...res.items);
          });
    }

    function addItem(item) {
      let type = 'Udp' + item.type.charAt(0).toUpperCase() + item.type.slice(1);
      window.mitt.emit(type, item.data);

      data.items.unshift(item);
      //pokud je pole vetsi nez 5, tak odstranime posledni prvek
      if (data.items.length > 5) {
        data.items.pop();
      }

    }

    function getCurrentDateInATOM() {
      const now = new Date();

      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0'); // Měsíc začíná od 0
      const day = String(now.getDate()).padStart(2, '0');
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      const seconds = String(now.getSeconds()).padStart(2, '0');

      const offset = -now.getTimezoneOffset();
      const offsetHours = String(Math.floor(offset / 60)).padStart(2, '0');
      const offsetMinutes = String(offset % 60).padStart(2, '0');
      const offsetString = `${offset >= 0 ? '+' : '-'}${offsetHours}:${offsetMinutes}`;

      return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${offsetString}`;
    }

    function addMessage(message) {
      addItem({type: 'message', createdAt: getCurrentDateInATOM(), data: message,});
    }

    function initWsSocket() {
      let wsUrl = props.wsurl;

      data.socket = new Sockette(wsUrl, {
        timeout: props.wsTimeout,
        maxAttempts: props.wsMaxAttempts,
        onopen: _ => {
          addMessage('Connected!');
          data.stateText = 'Connected';
          data.state = 'success';
        },
        onmessage: e => {
          let item = JSON.parse(e.data);
          addItem(item);
        },
        onreconnect: _ => {
          addMessage('Reconnecting...');
          data.stateText = 'Reconnecting';
          data.state = 'warning';
        },
        onmaximum: _ => {
          addMessage('Stop Attempting!');
          data.stateText = 'Stop attempting';
          data.state = 'danger';
        },
        onclose: _ => {
          addMessage('Connection closed!');
          data.stateText = 'Disconnected';
          data.state = 'danger';
        },
        onerror: e => {
          console.log(e);
          // addMessage('Error: ' + e);
        }
      });
    }

    onMounted(() => {
      // load();
      initWsSocket();
      window.mitt.on('liveData', (value) => {
        data.liveData = value;
        if (value) {
          data.socket.open();
        } else {
          data.socket.close();
        }
      });
    });

    return {
      ...toRefs(data)
    }
  }
}
</script>

<template>
  <div class="d-flex gap-2 flex-column">
    <data-paginator
      v-if="!liveData"
      :rider-id="riderId"
    >
    </data-paginator>

    <el-tag :type="state" effect="dark">
      {{ stateText }}
    </el-tag>
    <ul class="list-group list-group-flush">
      <li v-for="item in items" :key="item.id" class="list-group-item">
        <span class="me-3">{{ item.createdAt }}</span>
        <span>{{ item }}</span>
      </li>
    </ul>
  </div>
</template>

<style scoped>

</style>