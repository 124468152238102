// CSS
// import './Css/vertical-layout-light/style.scss';
// import './Css/style.scss';
// import "@mdi/font/scss/materialdesignicons.scss";
// import "simple-line-icons/scss/simple-line-icons.scss";

// CSS
import 'animate.css/animate.css';

//Fontawesome PRO
import './Css/fontawesome/scss/fontawesome.scss';
import './Css/fontawesome/scss/light.scss';
import './Css/fontawesome/scss/brands.scss';
// import './Css/fontawesome/scss/regular.scss';
// import './Css/fontawesome/scss/solid.scss';

import './Css/style.scss';

//JS
import 'bootstrap';

// import './Js/template';

import helpers from "./helpers";
window.helpers = helpers;

//NETTE
import naja from 'naja';
import netteForms from 'nette-forms';

//Element-plus
import ElementPlus from 'element-plus';
import elementCS from 'element-plus/es/locale/lang/cs'
import elementEN from 'element-plus/es/locale/lang/en'
import 'element-plus/dist/index.css';

import {createApp} from 'vue/dist/vue.esm-bundler';
import { createI18n } from 'vue-i18n'

//MITT
import mitt from 'mitt';
window.mitt = mitt();

function getLocale() {
	return document.documentElement.lang;
}

function getElementLocale() {
	return getLocale() === 'cs' ? elementCS : elementEN;
}

const i18n = createI18n({
	locale: getLocale(),
	fallbackLocale: 'cs',
	legacy: false,
	messages: {
		en: {
			lang: require('./../Lang/en/lang.en.json'),
			info: require('./../Lang/en/info.en.json'),
		},
		cs: {
			lang: require('./../Lang/cs/lang.cs.json'),
			info: require('./../Lang/cs/info.cs.json'),
		}
	}
});

export { createI18n, ElementPlus, getElementLocale, createApp, naja, netteForms, i18n};
