<script>
import {reactive, toRefs} from 'vue';
import router from "../router";

export default {
  name: 'ContactForm',
  setup() {
    const data = reactive({
      elForm: null,
      flash: null,
      form: {
        fullname: null,
        email: null,
        message: null
      },
    });

    function submit() {
      data.elForm.validate(valid => {
        if (valid) {
          window.helpers.server
              .fetchPost(router.front.xhr.registration, {
                action: 'contact',
                ...data.form
              })
              .then(response => {
                data.flash = response.flash;
                data.elForm.resetFields();
              });
        }
      });
    }

    return {
      ...toRefs(data),
      submit
    }
  }
}
</script>

<template>
  <el-form
      class="contact-form"
      label-width="110px"
      ref="elForm"
      :model="form"
      @submit.nativr.prevent="submit">
    <el-form-item label="Vaše jméno"
                  prop="fullname"
                  class="flex-column flex-md-row"
                  :rules="[ { required: true, message: 'Vyplňte prosím vaše jméno' } ]"
    >
      <el-input v-model="form.fullname"
                placeholder="Vaše celé jméno"
                :maxlength="30"
                show-word-limit></el-input>
    </el-form-item>

    <el-form-item label="Váš e-mail"
                  prop="email"
                  class="flex-column flex-md-row"
                  :rules="[
                      {
                        required: true,
                        message: 'Vyplňte prosím váš e-mail'
                      },
                      {
                        type: 'email',
                        message: 'Zadejte prosím správnou e-mailovou adresu',
                        trigger: ['blur', 'change'],
                      }
                  ]">
      <el-input v-model="form.email"
                placeholder="Váš e-mail"
                :maxlength="50"
                show-word-limit></el-input>
    </el-form-item>

    <el-form-item label="Vaše zpráva"
                  prop="message"
                  class="flex-column flex-md-row"
                  :rules="[ { required: true, message: 'Vyplňte prosím vaši zprávu pro nás' } ]"
    >
      <el-input v-model="form.message"
                placeholder="Vaše zpráva"
                type="textarea"
                :rows="5"
      ></el-input>
    </el-form-item>

    <el-form-item class="flex-column flex-md-row">
      <el-button type="success" size="large" native-type="submit">Odeslat</el-button>
      <el-alert v-if="flash" class="mt-3" :title="flash.message" :type="flash.type" closable @close="flash = null" effect="dark"></el-alert>
    </el-form-item>

  </el-form>
</template>

<style>
.contact-form .el-form-item__label {
  display: block;
  margin-left: .5rem;
}
</style>

<style scoped>
.contact-form {
  background-color: #fbfcff;
  padding: 24px 8px 8px 8px;
}
</style>