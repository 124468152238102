<script>
import {onMounted, reactive, toRefs} from 'vue';
import router from "../../router";

export default {
  name: 'LinkRiders',
  setup() {
    const data = reactive({
      riders: [],
    });

    function load() {
      helpers.server.fetchPost(router.front.xhr.view, {action: 'linkRiders'})
          .then((riders) => {
            data.riders = riders;
          });

    }

    onMounted(() => {
      load();
    });

    return {
      ...toRefs(data),
    }
  }
}
</script>

<template>
<div class="riders">
  <a v-for="rider in riders" :key="rider.id" :href="rider.link"><el-tag>{{ rider.nickname }}</el-tag></a>
</div>
</template>

<style scoped>
.riders {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
</style>